@charset "UTF-8";
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "YuGothic",
"Yu Gothic Medium""Yu Gothic",
ヒラギノ角ゴシック Pro,
Hiragino Kaku Gothic Pro,
メイリオ,
Meiryo,
Osaka,
ＭＳ Ｐゴシック,
MS PGothic,
Verdana,
Arial,
sans-serif !default;

/// Text color
/// @type Color
$text-color: #333 !default;

/// Link color
/// @type Color
$link-color: #8FC212 !default;

/// All other color
/// @type Color
$color-primary: #006E37 !default;
$color-secondary: #00612F !default;

$color-success: green !default;
$color-warning: yellow !default;
$color-error: #e5004f !default;
$color-black: #000 !default;
$color-white: #FFF !default;

$color-black02: #313131 !default;
$color-black03: #707070 !default;
$color-green01: #8FC212 !default;
$color-blue01: #EEF8F3 !default;
$color-blue02: #0099CC !default;
$color-blue03: #29539B !default;
$color-gray01: #DBDBDB !default;
$color-gray02: #F7F7F7 !default;


$color-border: #CCCCCC;
$color-border01: #C6C6C6 !default;
$color-border02: #B6C4BB !default;
$color-border03: #D6D6D6 !default;
$bg-color01: #F3F3F3;
$bg-color02: #F53B09;
$bg-color03: #E9EFEB;
$bg-color04: #145533;
$bg-color05: #99BF59;
$bg-color06: #8F8F8F;

$bg-color08: #f5f5f5;

// Hover
$color-primary-hover: lighten($color-primary, 30%) !default;


/// Container's maximum width
/// @type Length
$max-width: 1000px !default;

/// Spacing on the left and the right
/// @type Length
$grid-gutter-width: 40px;
$grid-gutter-width-sp: 25px;

// Section spacing
$section-spacing: 30px;
$section-spacing-sp: $section-spacing / 2;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$point-up: (xs: 375.01px,
    sm: 576.01px,
    sp: 768.01px,
    md: 992.01px,
    lg: 1200.01px) !default;
$point-down: (xss: 320px,
    xs: 375px,
    sm: 576px,
    sp: 768px,
    md: 992px,
    lg: 1200px) !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
/// $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/img' !default;

$transition-duration: .3s !default;
