// -----------------------------------------------------------------------------
// Keyv style
// -----------------------------------------------------------------------------

.contentFixL {
    .keyvFix {
        &:before {
            position: absolute;
            width: 100%;
            height: 250px;
            bottom: 0;
            left: 0;
            content: '';
            background: rgb(41,41,41);
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(41,41,41,1) 100%);
        }
        #pageAbout & {
            background: url(#{$base_url}/about/bg_about.jpg) no-repeat 50% 100%;
            background-size: cover;
        }
        #pageEducation & {
            background: url(#{$base_url}/education/bg_qualification.jpg) no-repeat 50% 100%;
            background-size: cover;
        }
        #pageExam & {
            background: url(#{$base_url}/exam/bg_faq.jpg) no-repeat 50% 100%;
            background-size: cover;
        }
    }
    @include media-down(md) {
        .keyvFix {
            &:before {
                display: none;
            }
        }
    }
}

.keyvFix {
    padding: 45px 45px 110px;
    display: flex;
    align-items: flex-end;
    min-height: 100vh;

    .txtKeyv {
        position: relative;
        z-index: 1;
        font-size: 4.1rem;
        color: $color-white;
        font-weight: bold;
        line-height: 1.3;
    }
}

@include media-down(md) {
    .keyvFix {
        padding:0;
        min-height: auto;
        padding-bottom: 80%;
        position: relative;

        .txtKeyv {
            font-size: 2.5rem;
            position: absolute;
            bottom: 72px;
            left: 22px;
        }
    }
}
