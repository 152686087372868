// -----------------------------------------------------------------------------
// Button style
// -----------------------------------------------------------------------------
.btnBox {
    text-align: center;
}

.btnDoc {
    display: inline-flex;
    align-items: center;
    padding: 18px 20px;
    text-decoration: none;
    border: 1px solid $color-border;
    transition: all 0.3s;
    &:hover {
        text-decoration: none;
        background-color: $bg-color08;
        color: $text-color;
    }
    .icon {
        margin-right: 20px;
        width: 17px;
        height: 22px;
    }
    span {
        width: calc(100% - 37px);
        line-height: 2;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: left;
    }
    @include media-down(sp) {
        padding: 13px 20px;
        .icon {
            width: 20px;
            height: 26px;
        }
        span {
            line-height: 1.5;
        }
    }
}

.departBtn {
    position: relative;
    display: block;
    padding: 12px 20px 10px 50px;
    font-size: 1.7rem;
    font-weight: normal;
    line-height: 1.5;
    border: 1px solid $color-border;
    background: url(/assets/img/svg/icon_arrow_right_round_green.svg) no-repeat 20px 50%/ 23px auto;
    transition: background-color .3s ease;
    &:hover {
        text-decoration: none;
        background-color: $bg-color08;
        color: $text-color;
    }
    @include media-down(sp) {
        padding: 11px 10px 9px 50px;
        font-size: 1.5rem;
    }
}

// Css fix on IE 11
@media all and (-ms-high-contrast:none) {
    .departBtn {
        padding: 16px 20px 9px 50px;
    }
}
