/* stylelint-disable */
/* ******************************************************************

    --common.css--

    0. BoxModel change

    1. Common setting
        1-1. Reset styles
        1-2. Font styles
        1-3. General styles
        1-4. Text styles
        1-5. List styles
        1-6. CSS3 common styles

****************************************************************** */

/*==================================================================
    0. BoxModel change
===================================================================*/

/*==================================================================
    1. Common setting
===================================================================*/

/* ------------------------------------------------------------------
    1-1. Reset styles
-------------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
object,
iframe,
pre,
code,
p,
blockquote,
form,
fieldset,
legend,
table,
th,
td,
caption,
tbody,
tfoot,
thead,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
audio,
video,
canvas {
    margin: 0;
    padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
menu {
    display: block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset,
img {
    border: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: normal;
}

ul,
ol,
menu {
    list-style: none;
}

caption,
th {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

q:before,
q:after {
    content: '';
}

abbr,
acronym {
    border: none;
    font-variant: normal;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

input,
textarea,
select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

legend {
    color: #000;
}

a img,
map a {
    border: none;
}

a:hover,
a:active,
a:focus {
    outline: 0;
}

embed {
    width: 100%;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img,
object,
embed {
    max-width: 100%;
    height: auto;
}

object,
embed {
    height: 100%;
}

img {
    -ms-interpolation-mode: bicubic;
}

/* Font styles
-------------------------------------------------------------------*/

select,
input,
button,
textarea,
button {
    font-size: inherit;
}

table {
    font-size: inherit;
    font: 100%;
}

pre,
code,
kbd,
samp,
tt {
    font-family: monospace;
    line-height: 1;
}

/* General styles
-------------------------------------------------------------------*/
table {
    empty-cells: show;
}

input {
    line-height: 1;
}

form img,
input,
select {
    vertical-align: middle;
}

textarea {
    resize: none;
}

select {
    padding: 1px;
}

label {
    margin-right: 5px;
}

legend {
    display: none;
}

input[type="text"],
input[type="password"],
textarea {
    padding: 3px 4px 0 3px;
    border: 1px solid #999;
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    font-size: 1.3rem;
}


/* ------------------------------------------------------------------
    1-2. VisualFormattingModel styles
-------------------------------------------------------------------*/
.noDisplay {
    display: none;
}

.block {
    display: block !important;
}

.inline {
    display: inline !important;
}

.inlineBlock {
    display: inline-block !important;
}

.static {
    position: static !important;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.fixed {
    position: fixed !important;
}

.leftBox {
    float: left;
}

.rightBox {
    float: right;
}

.nofloat {
    float: none !important;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "";
}

.clear {
    clear: both !important;
}

a.hover:hover,
input.hover:hover {
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    transition: opacity .3s ease;
}


/* ------------------------------------------------------------------
    1-3. BoxModel styles
-------------------------------------------------------------------*/
.auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.mt0,
.mv0,
.ma0 {
    margin-top: 0 !important;
}

.mr0,
.mh0,
.ma0 {
    margin-right: 0 !important;
}

.mb0,
.mv0,
.ma0 {
    margin-bottom: 0 !important;
}

.ml0,
.mh0,
.ma0 {
    margin-left: 0 !important;
}

.pt0,
.pv0,
.pa0 {
    padding-top: 0 !important;
}

.pr0,
.ph0,
.pa0 {
    padding-right: 0 !important;
}

.pb0,
.pv0,
.pa0 {
    padding-bottom: 0 !important;
}

.pl0,
.ph0,
.pa0 {
    padding-left: 0 !important;
}

.mt5,
.mv5,
.ma5 {
    margin-top: 5px !important;
}

.mr5,
.mh5,
.ma5 {
    margin-right: 5px !important;
}

.mb5,
.mv5,
.ma5 {
    margin-bottom: 5px !important;
}

.ml5,
.mh5,
.ma5 {
    margin-left: 5px !important;
}

.pt5,
.pv5,
.pa5 {
    padding-top: 5px !important;
}

.pr5,
.ph5,
.pa5 {
    padding-right: 5px !important;
}

.pb5,
.pv5,
.pa5 {
    padding-bottom: 5px !important;
}

.pl5,
.ph5,
.pa5 {
    padding-left: 5px !important;
}

.mt10,
.mv10,
.ma10 {
    margin-top: 10px !important;
}

.mr10,
.mh10,
.ma10 {
    margin-right: 10px !important;
}

.mb10,
.mv10,
.ma10 {
    margin-bottom: 10px !important;
}

.ml10,
.mh10,
.ma10 {
    margin-left: 10px !important;
}

.pt10,
.pv10,
.pa10 {
    padding-top: 10px !important;
}

.pr10,
.ph10,
.pa10 {
    padding-right: 10px !important;
}

.pb10,
.pv10,
.pa10 {
    padding-bottom: 10px !important;
}

.pl10,
.ph10,
.pa10 {
    padding-left: 10px !important;
}

.mt20,
.mv20,
.ma20 {
    margin-top: 20px !important;
}

.mr20,
.mh20,
.ma20 {
    margin-right: 20px !important;
}

.mb20,
.mv20,
.ma20 {
    margin-bottom: 20px !important;
}

.ml20,
.mh20,
.ma20 {
    margin-left: 20px !important;
}

.pt20,
.pv20,
.pa20 {
    padding-top: 20px !important;
}

.pr20,
.ph20,
.pa20 {
    padding-right: 20px !important;
}

.pb20,
.pv20,
.pa20 {
    padding-bottom: 20px !important;
}

.pl20,
.ph20,
.pa20 {
    padding-left: 20px !important;
}

.mt30,
.mv30,
.ma30 {
    margin-top: 30px !important;
}

.mr30,
.mh30,
.ma30 {
    margin-right: 30px !important;
}

.mb30,
.mv30,
.ma30 {
    margin-bottom: 30px !important;
}

.ml30,
.mh30,
.ma30 {
    margin-left: 30px !important;
}

.pt30,
.pv30,
.pa30 {
    padding-top: 30px !important;
}

.pr30,
.ph30,
.pa30 {
    padding-right: 30px !important;
}

.pb30,
.pv30,
.pa30 {
    padding-bottom: 30px !important;
}

.pl30,
.ph30,
.pa30 {
    padding-left: 30px !important;
}

.mt40,
.mv40,
.ma40 {
    margin-top: 40px !important;
}

.mr40,
.mh40,
.ma40 {
    margin-right: 40px !important;
}

.mb40,
.mv40,
.ma40 {
    margin-bottom: 40px !important;
}

.ml40,
.mh40,
.ma40 {
    margin-left: 40px !important;
}

.pt40,
.pv40,
.pa40 {
    padding-top: 40px !important;
}

.pr40,
.ph40,
.pa40 {
    padding-right: 40px !important;
}

.pb40,
.pv40,
.pa40 {
    padding-bottom: 40px !important;
}

.pl40,
.ph40,
.pa40 {
    padding-left: 40px !important;
}

.bt0,
.ba0 {
    border-top: none !important;
}

.br0,
.ba0 {
    border-right: none !important;
}

.bb0,
.ba0 {
    border-bottom: none !important;
}

.bl0,
.ba0 {
    border-left: none !important;
}


/* ------------------------------------------------------------------
    1-4. Text styles
-------------------------------------------------------------------*/
strong,
.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.note {
    margin-left: 1.0em;
    text-indent: -1.0em;
}

.f10 {
    font-size: 77%;
}

.f11 {
    font-size: 85%;
}

.f12 {
    font-size: 93%;
}

.f14 {
    font-size: 108%;
}

.f15 {
    font-size: 116%;
}

.f16 {
    font-size: 123.1%;
}

.f17 {
    font-size: 131%;
}

.f18 {
    font-size: 138.5%;
}

.f19 {
    font-size: 146.5%;
}

.f20 {
    font-size: 153.9%;
}

.f21 {
    font-size: 161.6%;
}

.f22 {
    font-size: 167%;
}

.f23 {
    font-size: 174%;
}

.f24 {
    font-size: 182%;
}

.f25 {
    font-size: 189%;
}

.f26 {
    font-size: 197%;
}

.red {
    color: #C00;
}

.white {
    color: #FFF;
}

.grayC {
    color: #CCC;
}

.gray9 {
    color: #999;
}

.gray6 {
    color: #666;
}

.gray3 {
    color: #333;
}

.black {
    color: #000;
}

.middle {
    vertical-align: middle !important;
}

.center {
    text-align: center !important;
}

.left {
    text-align: left !important;
}

.right {
    text-align: right !important;
}

.underline {
    text-decoration: underline !important;
}


/* ------------------------------------------------------------------
    1-5. List styles
-------------------------------------------------------------------*/
.disc {
    padding-left: 2em;
    list-style: disc;
}

.circle {
    padding-left: 2em;
    list-style: circle;
}

.decimal {
    padding-left: 2.4em;
    list-style: decimal;
}


/* ------------------------------------------------------------------
    1-6. CSS3 common styles
-------------------------------------------------------------------*/
.boxShadow {
    -webkit-box-shadow: 0 0 5px #DDD;
    box-shadow: 0 0 5px #DDD;
}

.textShadowW {
    text-shadow: 0 1px 0 #E7E7E7;
}

.textShadowB {
    text-shadow: 0 1px 0 #111;
}

.radius5 {
    border-radius: 5px;
}

.radius10 {
    border-radius: 10px;
}
