// -----------------------------------------------------------------------------
// Layout Settings
// -----------------------------------------------------------------------------
%center-content {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 $grid-gutter-width;
}

.inner {
    @extend %center-content;

    @include media-down(sp) {
        padding: 0 $grid-gutter-width-sp;
    }
}

#contents {
    width: 50%;
    margin-left: auto;
    background-color: $color-white;
}

.contentFixL {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    min-height: 100vh;
}

#main {
    width: 100%;
    margin-bottom: 130px;
}


@include media-down(md) {
    #wrapper {
        padding-bottom: 0 !important;
    }

    #contents {
        width: 100%;
        margin-left: 0;
    }

    .contentFixL {
        position: relative;
        width: 100%;
        min-height: auto;
    }
    #main {
        margin-bottom: 65px;
    }
}
