@charset "UTF-8";
/* stylelint-disable */
/* ******************************************************************

    --common.css--

    0. BoxModel change

    1. Common setting
        1-1. Reset styles
        1-2. Font styles
        1-3. General styles
        1-4. Text styles
        1-5. List styles
        1-6. CSS3 common styles

****************************************************************** */
/*==================================================================
    0. BoxModel change
===================================================================*/
/*==================================================================
    1. Common setting
===================================================================*/
/* ------------------------------------------------------------------
    1-1. Reset styles
-------------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
object,
iframe,
pre,
code,
p,
blockquote,
form,
fieldset,
legend,
table,
th,
td,
caption,
tbody,
tfoot,
thead,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
audio,
video,
canvas {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
menu {
  display: block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul,
ol,
menu {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: none;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

a img,
map a {
  border: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

embed {
  width: 100%;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

object,
embed {
  height: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* Font styles
-------------------------------------------------------------------*/
select,
input,
button,
textarea,
button {
  font-size: inherit;
}

table {
  font-size: inherit;
  font: 100%;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  line-height: 1;
}

/* General styles
-------------------------------------------------------------------*/
table {
  empty-cells: show;
}

input {
  line-height: 1;
}

form img,
input,
select {
  vertical-align: middle;
}

textarea {
  resize: none;
}

select {
  padding: 1px;
}

label {
  margin-right: 5px;
}

legend {
  display: none;
}

input[type=text],
input[type=password],
textarea {
  padding: 3px 4px 0 3px;
  border: 1px solid #999;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  font-size: 1.3rem;
}

/* ------------------------------------------------------------------
    1-2. VisualFormattingModel styles
-------------------------------------------------------------------*/
.noDisplay {
  display: none;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inlineBlock {
  display: inline-block !important;
}

.static {
  position: static !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.leftBox {
  float: left;
}

.rightBox {
  float: right;
}

.nofloat {
  float: none !important;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.clear {
  clear: both !important;
}

a.hover:hover,
input.hover:hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

/* ------------------------------------------------------------------
    1-3. BoxModel styles
-------------------------------------------------------------------*/
.auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mt0,
.mv0,
.ma0 {
  margin-top: 0 !important;
}

.mr0,
.mh0,
.ma0 {
  margin-right: 0 !important;
}

.mb0,
.mv0,
.ma0 {
  margin-bottom: 0 !important;
}

.ml0,
.mh0,
.ma0 {
  margin-left: 0 !important;
}

.pt0,
.pv0,
.pa0 {
  padding-top: 0 !important;
}

.pr0,
.ph0,
.pa0 {
  padding-right: 0 !important;
}

.pb0,
.pv0,
.pa0 {
  padding-bottom: 0 !important;
}

.pl0,
.ph0,
.pa0 {
  padding-left: 0 !important;
}

.mt5,
.mv5,
.ma5 {
  margin-top: 5px !important;
}

.mr5,
.mh5,
.ma5 {
  margin-right: 5px !important;
}

.mb5,
.mv5,
.ma5 {
  margin-bottom: 5px !important;
}

.ml5,
.mh5,
.ma5 {
  margin-left: 5px !important;
}

.pt5,
.pv5,
.pa5 {
  padding-top: 5px !important;
}

.pr5,
.ph5,
.pa5 {
  padding-right: 5px !important;
}

.pb5,
.pv5,
.pa5 {
  padding-bottom: 5px !important;
}

.pl5,
.ph5,
.pa5 {
  padding-left: 5px !important;
}

.mt10,
.mv10,
.ma10 {
  margin-top: 10px !important;
}

.mr10,
.mh10,
.ma10 {
  margin-right: 10px !important;
}

.mb10,
.mv10,
.ma10 {
  margin-bottom: 10px !important;
}

.ml10,
.mh10,
.ma10 {
  margin-left: 10px !important;
}

.pt10,
.pv10,
.pa10 {
  padding-top: 10px !important;
}

.pr10,
.ph10,
.pa10 {
  padding-right: 10px !important;
}

.pb10,
.pv10,
.pa10 {
  padding-bottom: 10px !important;
}

.pl10,
.ph10,
.pa10 {
  padding-left: 10px !important;
}

.mt20,
.mv20,
.ma20 {
  margin-top: 20px !important;
}

.mr20,
.mh20,
.ma20 {
  margin-right: 20px !important;
}

.mb20,
.mv20,
.ma20 {
  margin-bottom: 20px !important;
}

.ml20,
.mh20,
.ma20 {
  margin-left: 20px !important;
}

.pt20,
.pv20,
.pa20 {
  padding-top: 20px !important;
}

.pr20,
.ph20,
.pa20 {
  padding-right: 20px !important;
}

.pb20,
.pv20,
.pa20 {
  padding-bottom: 20px !important;
}

.pl20,
.ph20,
.pa20 {
  padding-left: 20px !important;
}

.mt30,
.mv30,
.ma30 {
  margin-top: 30px !important;
}

.mr30,
.mh30,
.ma30 {
  margin-right: 30px !important;
}

.mb30,
.mv30,
.ma30 {
  margin-bottom: 30px !important;
}

.ml30,
.mh30,
.ma30 {
  margin-left: 30px !important;
}

.pt30,
.pv30,
.pa30 {
  padding-top: 30px !important;
}

.pr30,
.ph30,
.pa30 {
  padding-right: 30px !important;
}

.pb30,
.pv30,
.pa30 {
  padding-bottom: 30px !important;
}

.pl30,
.ph30,
.pa30 {
  padding-left: 30px !important;
}

.mt40,
.mv40,
.ma40 {
  margin-top: 40px !important;
}

.mr40,
.mh40,
.ma40 {
  margin-right: 40px !important;
}

.mb40,
.mv40,
.ma40 {
  margin-bottom: 40px !important;
}

.ml40,
.mh40,
.ma40 {
  margin-left: 40px !important;
}

.pt40,
.pv40,
.pa40 {
  padding-top: 40px !important;
}

.pr40,
.ph40,
.pa40 {
  padding-right: 40px !important;
}

.pb40,
.pv40,
.pa40 {
  padding-bottom: 40px !important;
}

.pl40,
.ph40,
.pa40 {
  padding-left: 40px !important;
}

.bt0,
.ba0 {
  border-top: none !important;
}

.br0,
.ba0 {
  border-right: none !important;
}

.bb0,
.ba0 {
  border-bottom: none !important;
}

.bl0,
.ba0 {
  border-left: none !important;
}

/* ------------------------------------------------------------------
    1-4. Text styles
-------------------------------------------------------------------*/
strong,
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.note {
  margin-left: 1em;
  text-indent: -1em;
}

.f10 {
  font-size: 77%;
}

.f11 {
  font-size: 85%;
}

.f12 {
  font-size: 93%;
}

.f14 {
  font-size: 108%;
}

.f15 {
  font-size: 116%;
}

.f16 {
  font-size: 123.1%;
}

.f17 {
  font-size: 131%;
}

.f18 {
  font-size: 138.5%;
}

.f19 {
  font-size: 146.5%;
}

.f20 {
  font-size: 153.9%;
}

.f21 {
  font-size: 161.6%;
}

.f22 {
  font-size: 167%;
}

.f23 {
  font-size: 174%;
}

.f24 {
  font-size: 182%;
}

.f25 {
  font-size: 189%;
}

.f26 {
  font-size: 197%;
}

.red {
  color: #C00;
}

.white {
  color: #FFF;
}

.grayC {
  color: #CCC;
}

.gray9 {
  color: #999;
}

.gray6 {
  color: #666;
}

.gray3 {
  color: #333;
}

.black {
  color: #000;
}

.middle {
  vertical-align: middle !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.underline {
  text-decoration: underline !important;
}

/* ------------------------------------------------------------------
    1-5. List styles
-------------------------------------------------------------------*/
.disc {
  padding-left: 2em;
  list-style: disc;
}

.circle {
  padding-left: 2em;
  list-style: circle;
}

.decimal {
  padding-left: 2.4em;
  list-style: decimal;
}

/* ------------------------------------------------------------------
    1-6. CSS3 common styles
-------------------------------------------------------------------*/
.boxShadow {
  -webkit-box-shadow: 0 0 5px #DDD;
  box-shadow: 0 0 5px #DDD;
}

.textShadowW {
  text-shadow: 0 1px 0 #E7E7E7;
}

.textShadowB {
  text-shadow: 0 1px 0 #111;
}

.radius5 {
  border-radius: 5px;
}

.radius10 {
  border-radius: 10px;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
  background: #FFF;
  font-size: 10px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
}

/**
 * Basic styles for links
 */
a {
  color: #333;
  text-decoration: none;
}
a:hover {
  color: #8FC212;
  text-decoration: underline;
}

body {
  color: #333;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2;
  font-family: "YuGothic", "Yu Gothic Medium" "Yu Gothic", ヒラギノ角ゴシック Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, Verdana, Arial, sans-serif;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media only screen and (min-width: 992.01px) {
  .fixedHeader {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 99;
  }
  .fixedHeader .nav {
    display: flex;
  }
  .fixedHeader .nav li {
    width: calc(100% / 7);
    background-color: #313131;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    border-left: 1px solid #707070;
    transition: all 0.3s;
  }
  .fixedHeader .nav li:last-child {
    border-right: 1px solid #707070;
  }
  .fixedHeader .nav li:hover, .fixedHeader .nav li.active {
    background-color: #8FC212;
    border-color: #8FC212;
  }
  .fixedHeader .nav a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.61;
    padding: 17px 15px;
    height: 100%;
  }
  .fixedHeader .nav a:hover {
    text-decoration: none;
  }
  .fixedHeader .nav .img {
    display: block;
    line-height: 0;
    margin-right: 12px;
  }
  .fixedHeader .nav .img svg {
    max-height: 24px;
  }
  .fixedHeader .nav .img .iconNav01 {
    width: 27px;
  }
  .fixedHeader .nav .img .iconNav02 {
    width: 18px;
  }
  .fixedHeader .nav .img .iconNav03 {
    width: 22px;
  }
  .fixedHeader .nav .img .iconNav04 {
    width: 16px;
  }
  .fixedHeader .nav .img .iconNav05 {
    width: 22px;
  }
  .fixedHeader .nav .img .iconNav06 {
    width: 22px;
  }
  .fixedHeader .nav .img .iconNav07 {
    width: 18px;
  }

  .rHeader {
    background-color: #FFF;
    display: flex;
    position: fixed;
    width: 50%;
    top: 0;
    z-index: 99;
  }
  .rHeader .nav {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.67;
    padding: 6px 0px 0px;
    border-bottom: 1px solid #DBDBDB;
    width: calc(100% - 230px);
  }
  .rHeader .nav li:last-child a {
    border-right: none;
  }
  .rHeader .nav a {
    display: block;
    text-align: center;
    padding: 4px 11px 3px;
    border-right: 1px solid #333;
    margin-bottom: 5px;
  }
  .rHeader .nav a:hover {
    text-decoration: none;
  }
  .rHeader .iconNav {
    width: 230px;
    display: flex;
    align-self: flex-start;
    align-items: baseline;
    position: relative;
  }
  .rHeader .iconNav a {
    padding: 5px;
    width: 40px;
    height: 40px;
    min-height: 40px;
    text-align: center;
    background-color: #F3F3F3;
  }
  .rHeader .iconNav a:not(:last-child) {
    border-right: 1px solid #D6D6D6;
  }
  .rHeader .iconNav a .icon {
    fill: currentColor;
  }
  .rHeader .iconNav a.linkDoc {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #F53B09;
    width: 70px;
    min-width: 70px;
    height: 53px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    line-height: 1.66;
  }
  .rHeader .iconNav a.linkDoc .icon-doc {
    margin-right: 0;
    margin-bottom: 5px;
  }
}
.headerFixBlock {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 346px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  z-index: 99;
}
.headerFixBlock .menuBox {
  display: flex;
}
.headerFixBlock .logoBox {
  padding: 20px 13px 0;
  text-align: center;
  background-color: #FFF;
}
.headerFixBlock .logoBox span {
  display: block;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #006E37;
}
.headerFixBlock .logoBox .logo {
  display: inline-block;
  line-height: 0;
}

.btnMenu {
  display: inline-block;
  width: 111px;
  min-width: 111px;
  min-height: 111px;
  padding: 10px;
  background-color: #006E37;
  cursor: pointer;
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #FFF;
  text-align: center;
}
.btnMenu:after {
  position: absolute;
  content: "メニュー";
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0px 10px;
}
.btnMenu span {
  display: inline-block;
  position: relative;
  width: 45px;
  background-color: #FFF;
  height: 4px;
  border-radius: 1px;
  margin-top: 20px;
  transition: all 0.3s;
}
.btnMenu span:after, .btnMenu span:before {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 4px;
  background-color: #FFF;
  content: "";
  transition: all 0.3s;
}
.btnMenu span:before {
  top: 15px;
}
.btnMenu.active:after {
  content: "閉じる";
}
.btnMenu.active span {
  background-color: transparent;
}
.btnMenu.active span:after, .btnMenu.active span:before {
  top: 15px;
  transform: translateX(-50%) rotate(45deg);
}
.btnMenu.active span:before {
  transform: translateX(-50%) rotate(-45deg);
}

@media only screen and (max-width: 1200px) {
  .fixedHeader .nav a {
    padding: 16px 10px;
    font-size: 1.4rem;
  }

  .rHeader .nav {
    width: calc(100% - 150px);
    font-size: 1.1rem;
  }
  .rHeader .iconNav {
    width: 150px;
    flex-wrap: wrap;
    padding-right: 70px;
  }
  .rHeader .iconNav a {
    width: 50%;
    border-bottom: 1px solid #D6D6D6;
  }
  .rHeader .iconNav a.linkDoc {
    height: calc(100% + 8px);
  }
}
@media only screen and (max-width: 992px) {
  .fixedHeader,
.rHeader {
    display: none;
  }

  .headerFixBlock {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    max-width: 100%;
  }
  .headerFixBlock .logoBox {
    padding: 10px 10px 0;
    text-align: left;
    width: calc(100% - 68px);
  }
  .headerFixBlock .logoBox span {
    display: none;
  }
  .headerFixBlock .logoBox .logo img {
    max-width: 176px;
  }

  .btnMenu {
    order: 1;
    width: 68px;
    min-width: 68px;
    min-height: 68px;
    height: 68px;
    font-size: 1.1rem;
  }
  .btnMenu:after {
    bottom: 8px;
  }
  .btnMenu span {
    width: 28px;
    height: 2px;
    margin-top: 10px;
  }
  .btnMenu span:after, .btnMenu span:before {
    top: 20px;
    height: 2px;
  }
  .btnMenu span:before {
    top: 10px;
  }
  .btnMenu.active span:after, .btnMenu.active span:before {
    top: 10px;
  }
}
.navBox {
  display: none;
  background-color: #FFF;
  overflow-y: auto;
  overflow-x: hidden;
}

.listNav01 a {
  display: flex;
  align-items: center;
  padding: 14px 30px;
  border-top: 1px solid #FFF;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.6;
  background-color: #313131;
  color: #FFF;
  transition: background-color 0.3s ease;
}
.listNav01 a span {
  min-width: 27px;
  line-height: 0;
  display: inline-block;
  margin-right: 20px;
  text-align: right;
}
.listNav01 a:hover, .listNav01 a.active {
  text-decoration: none;
  background-color: #8FC212;
}
.listNav01 .icon-nav01 {
  width: 27px;
  height: 11px;
}
.listNav01 .icon-nav02 {
  width: 18px;
  height: 18px;
}
.listNav01 .icon-nav03 {
  width: 22px;
  height: 22px;
}
.listNav01 .icon-nav04 {
  width: 16px;
  height: 19px;
}
.listNav01 .icon-nav05 {
  width: 22px;
  height: 24px;
}
.listNav01 .icon-nav06 {
  width: 20px;
  height: 16px;
}
.listNav01 .icon-nav07 {
  width: 18px;
  height: 21px;
}
.listNav02, .listNav03 {
  display: flex;
  flex-wrap: wrap;
}
.listNav02 a, .listNav03 a {
  width: 50%;
  border-bottom: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  background-color: #FFF;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.8;
  padding: 9px 10px 9px 23px;
  color: #333;
  transition: color 0.3s ease;
  letter-spacing: -0.5px;
}
.listNav02 a:nth-child(2n), .listNav03 a:nth-child(2n) {
  border-right: 0;
}
.listNav02 a:hover, .listNav03 a:hover {
  color: #8FC212;
  text-decoration: none;
}
.listNav03 a {
  background-color: #F3F3F3;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
}
.listNav03 .icon {
  margin-right: 15px;
  fill: currentColor;
}

.linkDoc {
  display: flex;
  align-items: center;
  padding: 11px 35px;
  background-color: #F53B09;
  color: #FFF;
  font-size: 1.7rem;
  font-weight: bold;
  transition: color 0.3s ease;
}
.linkDoc:hover {
  text-decoration: none;
}
.linkDoc .icon-doc {
  fill: currentColor;
  width: 18px;
  height: 18px;
  margin-right: 25px;
}

@media only screen and (max-width: 992px) {
  .listNav01 a {
    padding: 14px 15px;
    font-size: 1.5rem;
    position: relative;
    padding-right: 55px;
  }
  .listNav01 a:first-child {
    border-top: 0;
  }
  .listNav01 a:after {
    position: absolute;
    content: "";
    right: 30px;
    top: 50%;
    width: 17px;
    height: 12px;
    background: url(/assets/img/svg/icon_arrow_white.svg) no-repeat 50% 50%;
    transform: translateY(-50%) rotate(180deg);
  }
  .listNav02 a, .listNav03 a {
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 10px 5px 10px 40px;
    background: url(/assets/img/svg/icon_arrow_green.svg) no-repeat left 20px top 46%;
  }
  .listNav03 a {
    background: #F3F3F3;
    padding: 10px;
  }

  .linkDoc {
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .listNav01 a {
    padding: 14px 10px;
  }
  .listNav02 a, .listNav03 a {
    background-position: left 15px top 46%;
    padding-left: 30px;
  }
  .listNav03 a {
    padding-left: 10px;
  }
}
.inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 40px;
}

@media only screen and (max-width: 768px) {
  .inner {
    padding: 0 25px;
  }
}

#contents {
  width: 50%;
  margin-left: auto;
  background-color: #FFF;
}

.contentFixL {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  min-height: 100vh;
}

#main {
  width: 100%;
  margin-bottom: 130px;
}

@media only screen and (max-width: 992px) {
  #wrapper {
    padding-bottom: 0 !important;
  }

  #contents {
    width: 100%;
    margin-left: 0;
  }

  .contentFixL {
    position: relative;
    width: 100%;
    min-height: auto;
  }

  #main {
    margin-bottom: 65px;
  }
}
.footerTop {
  background-color: #E9EFEB;
  padding: 35px 0px 5px;
}

.footerMid {
  padding: 15px 0px;
}
.footerMid .logo {
  line-height: 0;
}
.footerMid .logo img {
  max-width: 176px;
}
.footerMid .address {
  font-size: 1.3rem;
}
.footerMid .address span {
  font-weight: bold;
  margin-right: 15px;
}
.footerMid .address .link {
  display: inline-block;
}
.footerMid .address .link a {
  color: #29539B;
  background: url(/assets/img/svg/icon_arrow_blue.svg) no-repeat 0% 50%;
  padding-left: 15px;
}
.footerMid .address .link a:hover {
  color: #29539B;
}

.footerBot {
  padding: 17px 0px 8px;
  background-color: #145533;
}
.footerBot .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyRight {
  font-size: 1.3rem;
  color: #FFF;
  min-width: 115px;
  margin-left: 15px;
}

.logoFooterBox {
  padding: 8px 0px 0px;
  text-align: center;
}
.logoFooterBox .logo {
  display: inline-block;
  line-height: 0;
}

.navFooter {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0px 5px;
  border-style: solid;
  border-color: #C6C6C6;
  border-width: 1px 0px 1px 0px;
  margin-bottom: 20px;
}
.navFooter a {
  font-size: 1.35rem;
  font-weight: bold;
  padding: 0px 10px;
  line-height: 1.3;
  margin-bottom: 8px;
  transition: color 0.3s ease;
}
.navFooter a:not(:last-child) {
  border-right: 1px solid #333;
}
.navFooter a:hover {
  text-decoration: none;
}
.navFooter01 {
  padding: 10px 0px 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
}
.navFooter01 a {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 30px;
  padding-left: 22px;
  position: relative;
  line-height: 1.8;
  margin-bottom: 5px;
  transition: color 0.3s ease;
}
.navFooter01 a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #00612F;
}
.navFooter01 a:hover {
  text-decoration: none;
}
.navFooter02 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  width: calc(100% - 120px);
}
.navFooter02 a {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FFF;
  line-height: 1.3;
  padding: 0px 10px;
  margin-bottom: 5px;
}
.navFooter02 a:not(:last-child) {
  border-right: 1px solid #FFF;
}

.menuFooterBox {
  display: flex;
  justify-content: space-between;
}
.menuFooterBox .logoFooter {
  min-width: 67px;
}

@media only screen and (max-width: 768px) {
  .footerTop {
    padding: 0;
  }

  .footerMid {
    text-align: center;
    padding: 15px 0px;
  }
  .footerMid .address {
    font-size: 1.3rem;
  }
  .footerMid .address span {
    font-weight: bold;
    margin-right: 15px;
  }
  .footerMid .address .link {
    display: inline-block;
  }
  .footerMid .address .link a {
    color: #29539B;
    background: url(/assets/img/svg/icon_arrow_blue.svg) no-repeat 0% 50%;
    padding-left: 15px;
  }
  .footerMid .address .link a:hover {
    color: #29539B;
  }

  .footerBot {
    padding: 17px 0px 8px;
  }
  .footerBot .inner {
    flex-wrap: wrap;
  }

  .copyRight {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }

  .navFooter {
    display: none;
  }
  .navFooter01 {
    padding: 0;
    display: block;
  }
  .navFooter01 a {
    display: block;
    margin-right: 0px;
    padding: 10px 60px 10px 40px;
    margin-bottom: 0px;
    border-bottom: 1px solid #B6C4BB;
  }
  .navFooter01 a:first-child {
    border-top: 1px solid #B6C4BB;
  }
  .navFooter01 a:after, .navFooter01 a:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 2px;
    background-color: #333;
    border-radius: 50px;
    top: 50%;
    right: 40px;
    left: auto;
    transform: translateY(-50%);
  }
  .navFooter01 a:before {
    transform: translateY(-50%) rotate(90deg);
  }
  .navFooter01 a:hover:after, .navFooter01 a:hover:before {
    background-color: #8FC212;
  }
  .navFooter02 {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0px 5px;
    width: 100%;
    border-bottom: 1px solid #FFF;
    padding-bottom: 5px;
  }
  .navFooter02 a {
    padding: 0 5px;
    width: 50%;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 10px;
  }
  .navFooter02 a:nth-child(2n) {
    border-right: 0;
  }

  .menuFooterBox {
    display: block;
    margin: 0 -25px;
  }
  .menuFooterBox .logoFooter {
    display: none;
  }
}
@media only screen and (max-width: 375px) {
  .navFooter02 a {
    font-size: 1rem;
  }
}
.qaBlock {
  margin-bottom: 50px;
}
@media only screen and (max-width: 992px) {
  .qaBlock {
    margin-bottom: 30px;
  }
}

.qaWrap {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #CCCCCC;
}

.qaQuest {
  position: relative;
  display: block;
  padding: 10px 60px 10px 45px;
  font-size: 1.7rem;
  line-height: 1.5;
  cursor: pointer;
}
.qaQuest:hover {
  background-color: #f5f5f5;
}
.qaQuest::before {
  position: absolute;
  top: 13px;
  left: 20px;
  width: 17px;
  height: 16px;
  background: url(/assets/img/svg/icon_q.svg) no-repeat 0 0/17px auto;
  content: "";
}
.qaQuest::after {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 23px;
  height: 23px;
  background: url(/assets/img/svg/icon_arrow_round_down.svg) no-repeat 0 0/23px auto;
  transition: all 0.3s;
  transform: translateY(-50%);
  content: "";
}
.qaQuest.active::after {
  transform: rotate(180deg) translateY(50%);
}
@media only screen and (max-width: 768px) {
  .qaQuest {
    font-size: 1.5rem;
    padding: 10px 35px 10px 35px;
  }
  .qaQuest::before {
    background-size: 14px auto;
    width: 14px;
    height: 13px;
    left: 15px;
    top: 14px;
  }
  .qaQuest::after {
    background-size: 15px auto;
    right: 15px;
    width: 15px;
    height: 15px;
  }
}

.qaAnswer {
  display: none;
  position: relative;
  margin: 0 10px 10px 10px;
  padding: 20px 10px 20px 35px;
  font-size: 1.7rem;
  line-height: 1.9;
  background-color: #E9EFEB;
}
.qaAnswer .sub {
  position: relative;
  display: block;
  padding-left: 10px;
}
.qaAnswer .sub::before {
  position: absolute;
  top: 12px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333;
  content: "";
}
.qaAnswer::before {
  position: absolute;
  top: 28px;
  left: 10px;
  width: 14px;
  height: 15px;
  background: url(/assets/img/svg/icon_a.svg) no-repeat 0 0/14px auto;
  content: "";
}
.qaAnswer a {
  color: #0099CC;
  text-decoration: none;
}
.qaAnswer a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .qaAnswer {
    font-size: 1.5rem;
    padding: 10px 10px 10px 30px;
  }
  .qaAnswer .sub {
    padding-left: 5px;
  }
  .qaAnswer .sub::before {
    top: 12px;
    width: 3px;
    height: 3px;
  }
  .qaAnswer::before {
    top: 18px;
    left: 10px;
    width: 12px;
    height: 13px;
    background-size: 12px auto;
  }
}

@media all and (-ms-high-contrast: none) {
  .qaQuest {
    padding-top: 12px;
  }
}
.breadcrumbWrap {
  list-style: none;
  background-color: #EEF8F3;
  padding: 7px 75px 8px 45px;
}
.breadcrumbWrap .breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumbWrap li {
  font-size: 1.2rem;
  line-height: 1.67;
}
.breadcrumbWrap li + li:before {
  padding: 0 5px;
  color: #000;
  content: "＞";
}
.breadcrumbWrap li a {
  color: #0099CC;
  text-decoration: none;
}
.breadcrumbWrap li a:hover {
  color: #01447e;
  text-decoration: underline;
}
@media only screen and (max-width: 992px) {
  .breadcrumbWrap {
    background-color: #FFF;
    padding: 20px 25px;
  }
}

.btnBox {
  text-align: center;
}

.btnDoc {
  display: inline-flex;
  align-items: center;
  padding: 18px 20px;
  text-decoration: none;
  border: 1px solid #CCCCCC;
  transition: all 0.3s;
}
.btnDoc:hover {
  text-decoration: none;
  background-color: #f5f5f5;
  color: #333;
}
.btnDoc .icon {
  margin-right: 20px;
  width: 17px;
  height: 22px;
}
.btnDoc span {
  width: calc(100% - 37px);
  line-height: 2;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .btnDoc {
    padding: 13px 20px;
  }
  .btnDoc .icon {
    width: 20px;
    height: 26px;
  }
  .btnDoc span {
    line-height: 1.5;
  }
}

.departBtn {
  position: relative;
  display: block;
  padding: 12px 20px 10px 50px;
  font-size: 1.7rem;
  font-weight: normal;
  line-height: 1.5;
  border: 1px solid #CCCCCC;
  background: url(/assets/img/svg/icon_arrow_right_round_green.svg) no-repeat 20px 50%/23px auto;
  transition: background-color 0.3s ease;
}
.departBtn:hover {
  text-decoration: none;
  background-color: #f5f5f5;
  color: #333;
}
@media only screen and (max-width: 768px) {
  .departBtn {
    padding: 11px 10px 9px 50px;
    font-size: 1.5rem;
  }
}

@media all and (-ms-high-contrast: none) {
  .departBtn {
    padding: 16px 20px 9px 50px;
  }
}
.headline1 {
  margin-bottom: 40px;
  padding: 36px 20px;
  background-color: #006E37;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: #FFF;
}
@media only screen and (max-width: 768px) {
  .headline1 {
    margin-bottom: 20px;
    font-size: 2.3rem;
    padding: 12px 25px 12px 25px;
    text-align: left;
  }
}

.headline2 {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #99BF59;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #FFF;
}
@media only screen and (max-width: 768px) {
  .headline2 {
    font-size: 1.7rem;
    padding: 11px 20px;
    text-align: left;
    line-height: 1.3;
  }
}

.headline3 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
}
.headline3::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #8F8F8F;
  content: "";
}
.headline3::after {
  position: absolute;
  z-index: 2;
  max-width: 160px;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #8FC212;
  content: "";
}
@media only screen and (max-width: 768px) {
  .headline3 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 2rem;
    line-height: 1.3;
  }
}

@media all and (-ms-high-contrast: none) {
  .headline2 {
    padding: 12px 10px 6px 10px;
  }
}
.icon {
  transition: fill 0.25s;
  width: 1rem;
  height: 1rem;
  fill: #333;
}
.icon-trans {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.icon-map {
  width: 12px;
  min-width: 12px;
  height: 18px;
}
.icon-envelope {
  width: 17px;
  min-width: 17px;
  height: 13px;
}
.icon-search {
  width: 17px;
  min-width: 17px;
  height: 18px;
}

.iconBox {
  background-color: #bcbcbc;
}

.contentFixL .keyvFix:before {
  position: absolute;
  width: 100%;
  height: 250px;
  bottom: 0;
  left: 0;
  content: "";
  background: #292929;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #292929 100%);
}
#pageAbout .contentFixL .keyvFix {
  background: url(/assets/img/about/bg_about.jpg) no-repeat 50% 100%;
  background-size: cover;
}
#pageEducation .contentFixL .keyvFix {
  background: url(/assets/img/education/bg_qualification.jpg) no-repeat 50% 100%;
  background-size: cover;
}
#pageExam .contentFixL .keyvFix {
  background: url(/assets/img/exam/bg_faq.jpg) no-repeat 50% 100%;
  background-size: cover;
}
@media only screen and (max-width: 992px) {
  .contentFixL .keyvFix:before {
    display: none;
  }
}

.keyvFix {
  padding: 45px 45px 110px;
  display: flex;
  align-items: flex-end;
  min-height: 100vh;
}
.keyvFix .txtKeyv {
  position: relative;
  z-index: 1;
  font-size: 4.1rem;
  color: #FFF;
  font-weight: bold;
  line-height: 1.3;
}

@media only screen and (max-width: 992px) {
  .keyvFix {
    padding: 0;
    min-height: auto;
    padding-bottom: 80%;
    position: relative;
  }
  .keyvFix .txtKeyv {
    font-size: 2.5rem;
    position: absolute;
    bottom: 72px;
    left: 22px;
  }
}
.choseItem {
  display: flex;
  margin-bottom: 40px;
}
.choseItem:last-child {
  margin-bottom: 0;
}
.choseItem .image {
  width: 200px;
  height: 150px;
  margin-right: 30px;
}
.choseItem .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.choseItem .image.isIE {
  background-size: cover;
  background-position: 50% 50%;
}
.choseItem .image.isIE img {
  display: none;
}
.choseItem .choseBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 230px);
  padding-top: 10px;
}
.choseItem .choseBox .textList li {
  position: relative;
  padding-left: 18px;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.6;
}
.choseItem .choseBox .textList li::after {
  position: absolute;
  border-radius: 50%;
  left: 7px;
  top: 10px;
  width: 3px;
  height: 3px;
  background-color: #333;
  content: "";
}
@media only screen and (max-width: 1500px) {
  .choseItem .choseBox .textList li {
    font-size: 1.3vw;
  }
}
@media only screen and (max-width: 1200px) {
  .choseItem .choseBox .textList li {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 768px) {
  .choseItem .choseBox .textList li {
    font-size: 1.5rem;
  }
}
.choseItem .choseBox .departBtn {
  margin-top: 5px;
}
@media only screen and (max-width: 768px) {
  .choseItem {
    flex-direction: column;
  }
  .choseItem .image {
    margin: 0 auto 15px auto;
    width: 164px;
    height: 123px;
  }
  .choseItem .choseBox {
    width: 100%;
    padding-top: 0;
  }
  .choseItem .choseBox .textList {
    margin-bottom: 10px;
  }
}

.linkList li {
  margin-bottom: 11px;
}
.linkList li:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 992px) {
  .linkList li {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 1500px) {
  .tableBlock01 {
    overflow: auto;
    margin-right: -40px;
  }
  .tableBlock01 .tableBox {
    padding-right: 40px;
    width: min-content;
  }
}
@media only screen and (max-width: 992px) {
  .tableBlock01 {
    overflow: inherit;
    margin-right: 0;
  }
  .tableBlock01 .tableBox {
    padding-right: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .tableBlock01 {
    overflow: auto;
    margin-right: -20px;
  }
  .tableBlock01 .tableBox {
    padding-right: 20px;
    width: min-content;
  }
}

.table01 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table01 th, .table01 td {
  border: 1px solid #CCCCCC;
  font-size: 1.4rem;
  line-height: 1.6;
  padding: 10px 10px;
  min-width: 115px;
}
.table01 thead th {
  background-color: #E9EFEB;
  text-align: center;
  font-weight: bold;
}
.table01 thead th.col1 {
  width: 22%;
}
.table01 thead th.col2 {
  width: 48%;
}
.table01 thead th.col3 {
  width: 13%;
}
.table01 thead th.col4 {
  width: 17%;
}
.table01 th {
  font-weight: bold;
}
@media only screen and (max-width: 1500px) {
  .table01 {
    min-width: 820px;
  }
}
@media only screen and (max-width: 992px) {
  .table01 {
    min-width: auto;
  }
}
@media only screen and (max-width: 768px) {
  .table01 {
    min-width: 820px;
  }
}

.futureSection {
  margin-bottom: 150px;
}
.futureSection .txt {
  font-size: 1.7rem;
  line-height: 1.6;
}
.futureSection .imgBox {
  padding-top: 60px;
  margin: 0 auto 30px auto;
  width: 100%;
  max-width: 440px;
}
.futureSection .imgBox img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .futureSection {
    margin-bottom: 40px;
  }
  .futureSection .txt {
    font-size: 1.5rem;
  }
  .futureSection .imgBox {
    padding-top: 30px;
    margin-bottom: 20px;
  }
}

.choseSection {
  margin-bottom: 150px;
}
.choseSection .txt {
  font-size: 1.7rem;
  line-height: 1.6;
  margin-bottom: 20px;
}
.choseSection .tableBlock01 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 992px) {
  .choseSection {
    margin-bottom: 40px;
  }
  .choseSection .txt {
    font-size: 1.5rem;
  }
  .choseSection .tableBlock01 {
    margin-bottom: 20px;
  }
}

.profileSection .txt {
  font-size: 1.7rem;
  line-height: 2.11765;
}
.profileSection .imgBox {
  max-width: 600px;
  margin: 60px auto 53px;
  line-height: 0;
}
.profileSection .profileBox {
  background-color: #F7F7F7;
  padding: 30px 25px 25px;
  margin-bottom: 130px;
}
.profileSection .profileBox .ttl {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.588;
  margin-bottom: 15px;
}
.profileSection .profileBox .txt02 {
  font-size: 1.6rem;
  line-height: 1.5;
}
.profileSection .profileBox02 {
  display: flex;
  flex-wrap: wrap;
  background-color: #F7F7F7;
  margin-top: 30px;
}
.profileSection .profileBox02 .img {
  width: 150px;
  line-height: 0;
}
.profileSection .profileBox02 .info {
  width: calc(100% - 150px);
  padding: 25px;
}
.profileSection .profileBox02 .name {
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.588;
  margin-bottom: 15px;
}
.profileSection .profileBox02 .txt03 {
  font-size: 1.6rem;
  line-height: 1.5;
}
@media only screen and (max-width: 992px) {
  .profileSection .txt {
    font-size: 1.5rem;
    line-height: 1.67;
  }
  .profileSection .imgBox {
    margin: 45px auto 23px;
  }
  .profileSection .profileBox {
    padding: 19px 20px 26px;
    margin-bottom: 60px;
  }
  .profileSection .profileBox .ttl {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  .profileSection .profileBox .txt02 {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .profileSection .profileBox02 {
    margin-top: 20px;
  }
  .profileSection .profileBox02 .name {
    display: flex;
    align-items: center;
  }
  .profileSection .profileBox02 .img {
    width: 80px;
    margin-right: 23px;
  }
  .profileSection .profileBox02 .info {
    width: 100%;
    padding: 20px;
  }
  .profileSection .profileBox02 .name {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .profileSection .profileBox02 .txt03 {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .profileSection .headline3 {
    padding-bottom: 18px;
  }
}

.noBg {
  background-image: none !important;
}

.spDisplay {
  display: none;
}

.pcDisplay {
  display: block;
}

@media only screen and (max-width: 768px) {
  .spDisplay {
    display: block;
  }

  .pcDisplay {
    display: none;
  }
}
.spDisplay01 {
  display: none;
}

.pcDisplay01 {
  display: block;
}

@media only screen and (max-width: 992px) {
  .spDisplay01 {
    display: block;
  }

  .pcDisplay01 {
    display: none;
  }
}