// -----------------------------------------------------------------------------
// All other style
// -----------------------------------------------------------------------------
.futureSection {
    margin-bottom: 150px;
    .txt {
        font-size: 1.7rem;
        line-height: 1.6;
    }
    .imgBox {
        padding-top: 60px;
        margin: 0 auto 30px auto;
        width: 100%;
        max-width: 440px;
        img {
            display: block;
            width: 100%;
        }
    }

    @include media-down(md) {
        margin-bottom: 40px;
        .txt {
            font-size: 1.5rem;
        }
        .imgBox {
            padding-top: 30px;
            margin-bottom: 20px;
        }
    }
}
.choseSection {
    margin-bottom: 150px;
    .txt {
        font-size: 1.7rem;
        line-height: 1.6;
        margin-bottom: 20px;
    }
    .tableBlock01 {
        margin-bottom: 30px;
    }
    @include media-down(md) {
        margin-bottom: 40px;
        .txt {
            font-size: 1.5rem;
        }
        .tableBlock01 {
            margin-bottom: 20px;
        }
    }
}
.profileSection {
    .txt {
        font-size: 1.7rem;
        line-height: 2.11765;
    }
    .imgBox {
        max-width: 600px;
        margin: 60px auto 53px;
        line-height: 0;
    }
    .profileBox {
        background-color: $color-gray02;
        padding: 30px 25px 25px;
        margin-bottom: 130px;
        .ttl {
            font-size: 1.7rem;
            font-weight: bold;
            line-height: 1.588;
            margin-bottom: 15px;
        }
        .txt02 {
            font-size: 1.6rem;
            line-height: 1.5;
        }
    }
    .profileBox02 {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-gray02;
        margin-top: 30px;
        .img {
            width: 150px;
            line-height: 0;
        }
        .info {
            width: calc(100% - 150px);
            padding: 25px;
        }
        .name {
            font-size: 1.7rem;
            font-weight: bold;
            line-height: 1.588;
            margin-bottom: 15px;
        }
        .txt03 {
            font-size: 1.6rem;
            line-height: 1.5;
        }
    }
    @include media-down(md) {
        .txt {
            font-size: 1.5rem;
            line-height: 1.67;
        }
        .imgBox {
            margin: 45px auto 23px;
        }
        .profileBox {
            padding: 19px 20px 26px;
            margin-bottom: 60px;
            .ttl {
                font-size: 1.4rem;
                line-height: 1.5;
                margin-bottom: 12px;
            }
            .txt02 {
                font-size: 1.4rem;
                line-height: 1.5;
            }

        }
        .profileBox02 {
            margin-top: 20px;
            .name {
                display: flex;
                align-items: center;
            }
            .img {
                width: 80px;
                margin-right: 23px;
            }
            .info {
                width: 100%;
                padding: 20px;
            }
            .name {
                font-size: 1.4rem;
                line-height: 1.5;
            }
            .txt03 {
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
        .headline3 {
            padding-bottom: 18px;
        }
    }
}
.noBg {
    background-image: none !important;
}

.spDisplay {
    display: none;
}

.pcDisplay {
    display: block;
}

@include media-down(sp) {
    .spDisplay {
        display: block;
    }

    .pcDisplay {
        display: none;
    }
}
.spDisplay01 {
    display: none;
}

.pcDisplay01 {
    display: block;
}

@include media-down(md) {
    .spDisplay01 {
        display: block;
    }

    .pcDisplay01 {
        display: none;
    }
}
