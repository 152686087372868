// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------
body {
    color: $text-color;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2;
    font-family: $text-font-stack;
}
