// -----------------------------------------------------------------------------
// Footer style
// -----------------------------------------------------------------------------
.footerTop {
    background-color: $bg-color03;
    padding: 35px 0px 5px;
}

.footerMid {
    padding: 15px 0px;

    .logo {
        line-height: 0;

        img {
            max-width: 176px;
        }
    }

    .address {
        font-size: 1.3rem;

        span {
            font-weight: bold;
            margin-right: 15px;
        }

        .link {
            display: inline-block;

            a {
                color: $color-blue03;
                background: url(#{$base_url}/svg/icon_arrow_blue.svg) no-repeat 0% 50%;
                padding-left: 15px;

                &:hover {
                    color: $color-blue03;
                }
            }
        }
    }
}

.footerBot {
    padding: 17px 0px 8px;
    background-color: $bg-color04;

    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.copyRight {
    font-size: 1.3rem;
    color: $color-white;
    min-width: 115px;
    margin-left: 15px;
}

.logoFooterBox {
    padding: 8px 0px 0px;
    text-align: center;

    .logo {
        display: inline-block;
        line-height: 0;
    }
}

.navFooter {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0px 5px;
    border-style: solid;
    border-color: $color-border01;
    border-width: 1px 0px 1px 0px;
    margin-bottom: 20px;

    a {
        font-size: 1.35rem;
        font-weight: bold;
        padding: 0px 10px;
        line-height: 1.3;
        margin-bottom: 8px;
        transition: color .3s ease;

        &:not(:last-child) {
            border-right: 1px solid $text-color;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &01 {
        padding: 10px 0px 10px 20px;
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;

        a {
            font-size: 1.5rem;
            font-weight: bold;
            margin-right: 30px;
            padding-left: 22px;
            position: relative;
            line-height: 1.8;
            margin-bottom: 5px;
            transition: color .3s ease;

            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 6px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $color-secondary;
            }

            &:hover {
                text-decoration: none;
            }
        }

    }

    &02 {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        width: calc(100% - 120px);

        a {
            font-size: 1.2rem;
            font-weight: bold;
            color: $color-white;
            line-height: 1.3;
            padding: 0px 10px;
            margin-bottom: 5px;

            &:not(:last-child) {
                border-right: 1px solid $color-white;
            }
        }
    }
}

.menuFooterBox {
    display: flex;
    justify-content: space-between;

    .logoFooter {
        min-width: 67px;
    }
}

@include media-down(sp) {
    .footerTop {
        padding: 0;
    }

    .footerMid {
        text-align: center;
        padding: 15px 0px;

        .address {
            font-size: 1.3rem;

            span {
                font-weight: bold;
                margin-right: 15px;
            }

            .link {
                display: inline-block;

                a {
                    color: $color-blue03;
                    background: url(#{$base_url}/svg/icon_arrow_blue.svg) no-repeat 0% 50%;
                    padding-left: 15px;

                    &:hover {
                        color: $color-blue03;
                    }
                }
            }
        }
    }

    .footerBot {
        padding: 17px 0px 8px;

        .inner {
            flex-wrap: wrap;
        }
    }

    .copyRight {
        margin-left: 0;
        text-align: center;
        width: 100%;
    }

    .navFooter {
        display: none;

        &01 {
            padding: 0;
            display: block;

            a {
                display: block;
                margin-right: 0px;
                padding: 10px 60px 10px 40px;
                margin-bottom: 0px;
                border-bottom: 1px solid $color-border02;

                &:first-child {
                    border-top: 1px solid $color-border02;
                }

                &:after,
                &:before {
                    position: absolute;
                    content: "";
                    width: 14px;
                    height: 2px;
                    background-color: $text-color;
                    border-radius: 50px;
                    top: 50%;
                    right: 40px;
                    left: auto;
                    transform: translateY(-50%);
                }

                &:before {
                    transform: translateY(-50%) rotate(90deg);
                }

                &:hover {

                    &:after,
                    &:before {
                        background-color: $link-color;
                    }
                }

            }

        }

        &02 {
            display: flex;
            flex-wrap: wrap;
            margin: 0px 0px 5px;
            width: 100%;
            border-bottom: 1px solid $color-white;
            padding-bottom: 5px;

            a {
                padding: 0 5px;
                width: 50%;
                font-size: 1rem;
                text-align: center;
                margin-bottom: 10px;

                &:nth-child(2n) {
                    border-right: 0;
                }
            }
        }
    }

    .menuFooterBox {
        display: block;
        margin: 0 -25px;

        .logoFooter {
            display: none;
        }
    }
}

@include media-down(xs) {
    .navFooter {
        &02 {
            a {
                font-size: 1rem;
            }
        }
    }
}
