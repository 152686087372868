// -----------------------------------------------------------------------------
// Navi style
// -----------------------------------------------------------------------------
.navBox {
    display: none;
    background-color: $color-white;
    overflow-y: auto;
    overflow-x: hidden;
}

.listNav {
    &01 {
        a {
            display: flex;
            align-items: center;
            padding: 14px 30px;
            border-top: 1px solid $color-white;
            font-size: 1.7rem;
            font-weight: bold;
            line-height: 1.6;
            background-color: $color-black02;
            color: $color-white;
            transition: background-color 0.3s ease;

            span {
                min-width: 27px;
                line-height: 0;
                display: inline-block;
                margin-right: 20px;
                text-align: right;
            }

            &:hover,
            &.active {
                text-decoration: none;
                background-color: $link-color;
            }
        }

        .icon-nav01 {
            width: 27px;
            height: 11px;
        }

        .icon-nav02 {
            width: 18px;
            height: 18px;
        }

        .icon-nav03 {
            width: 22px;
            height: 22px;
        }

        .icon-nav04 {
            width: 16px;
            height: 19px;
        }

        .icon-nav05 {
            width: 22px;
            height: 24px;
        }

        .icon-nav06 {
            width: 20px;
            height: 16px;
        }

        .icon-nav07 {
            width: 18px;
            height: 21px;
        }
    }

    &02 {
        display: flex;
        flex-wrap: wrap;

        a {
            width: 50%;
            border-bottom: 1px solid $color-border;
            border-right: 1px solid $color-border;
            background-color: $color-white;
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 1.8;
            padding: 9px 10px 9px 23px;
            color: $text-color;
            transition: color .3s ease;
            letter-spacing: -0.5px;

            &:nth-child(2n) {
                border-right: 0;

            }

            &:hover {
                color: $link-color;
                text-decoration: none;
            }
        }
    }

    &03 {
        @extend .listNav02;

        a {
            background-color: $bg-color01;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0;
        }

        .icon {
            margin-right: 15px;
            fill: currentColor;
        }
    }
}

.linkDoc {
    display: flex;
    align-items: center;
    padding: 11px 35px;
    background-color: $bg-color02;
    color: $color-white;
    font-size: 1.7rem;
    font-weight: bold;
    transition: color .3s ease;

    &:hover {
        text-decoration: none;
    }

    .icon-doc {
        fill: currentColor;
        width: 18px;
        height: 18px;
        margin-right: 25px;
    }
}

@include media-down(md) {
    .listNav {
        &01 {
            a {
                padding: 14px 15px;
                font-size: 1.5rem;
                position: relative;
                padding-right: 55px;

                &:first-child {
                    border-top: 0;
                }

                &:after {
                    position: absolute;
                    content: "";
                    right: 30px;
                    top: 50%;
                    width: 17px;
                    height: 12px;
                    background: url(#{$base_url}/svg/icon_arrow_white.svg) no-repeat 50% 50%;
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        &02 {
            a {
                font-size: 1.3rem;
                line-height: 1.5;
                padding: 10px 5px 10px 40px;
                background: url(#{$base_url}/svg/icon_arrow_green.svg) no-repeat left 20px top 46%;
            }
        }

        &03 {
            a {
                background: $bg-color01;
                padding: 10px;
            }
        }
    }

    .linkDoc {
        justify-content: center;
    }
}

@include media-down(sm) {
    .listNav {
        &01 {
            a {
                padding: 14px 10px;
            }
        }

        &02 {
            a {
                background-position: left 15px top 46%;
                padding-left: 30px;
            }
        }

        &03 {
            a {
                padding-left: 10px;
            }
        }
    }
}
