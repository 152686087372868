// -----------------------------------------------------------------------------
// Icon style
// -----------------------------------------------------------------------------
.icon {
    transition: fill .25s;
    width: 1rem;
    height: 1rem;
    fill: $text-color;

    &-trans {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    &-map {
        width: 12px;
        min-width: 12px;
        height: 18px;
    }

    &-envelope {
        width: 17px;
        min-width: 17px;
        height: 13px;
    }

    &-search {
        width: 17px;
        min-width: 17px;
        height: 18px;
    }
}

.iconBox {
    background-color: #bcbcbc;
}
