// -----------------------------------------------------------------------------
// Accordion style
// -----------------------------------------------------------------------------
.qaBlock {
    margin-bottom: 50px;

    @include media-down(md) {
        margin-bottom: 30px;
    }
}

.qaWrap {
    position: relative;
    margin-bottom: 10px;
    border: 1px solid $color-border;
}

.qaQuest {
    position: relative;
    display: block;
    padding: 10px 60px 10px 45px;
    font-size: 1.7rem;
    line-height: 1.5;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;        
    }
    &::before {
        position: absolute;
        top: 13px;
        left: 20px;
        width: 17px;
        height: 16px;
        background: url(/assets/img/svg/icon_q.svg) no-repeat 0 0/17px auto;
        content: '';
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 23px;
        height: 23px;
        background: url(/assets/img/svg/icon_arrow_round_down.svg) no-repeat 0 0/23px auto;
        transition: all 0.3s;
        transform: translateY(-50%);
        content: '';
    }

    &.active {
        &::after {
            transform: rotate(180deg) translateY(50%);
        }
    }

    @include media-down(sp) {
        font-size: 1.5rem;
        padding: 10px 35px 10px 35px;

        &::before {
            background-size: 14px auto;
            width: 14px;
            height: 13px;
            left: 15px;
            top: 14px;
        }

        &::after {
            background-size: 15px auto;
            right: 15px;
            width: 15px;
            height: 15px;
        }
    }
}

.qaAnswer {
    display: none;
    position: relative;
    margin: 0 10px 10px 10px;
    padding: 20px 10px 20px 35px;
    font-size: 1.7rem;
    line-height: 1.9;
    background-color: $bg-color03;

    .sub {
        position: relative;
        display: block;
        padding-left: 10px;

        &::before {
            position: absolute;
            top: 12px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $text-color;
            content: '';
        }
    }

    &::before {
        position: absolute;
        top: 28px;
        left: 10px;
        width: 14px;
        height: 15px;
        background: url(/assets/img/svg/icon_a.svg) no-repeat 0 0/14px auto;
        content: '';
    }

    a {
        color: $color-blue02;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include media-down(sp) {
        font-size: 1.5rem;
        padding: 10px 10px 10px 30px;

        .sub {
            padding-left: 5px;

            &::before {
                top: 12px;
                width: 3px;
                height: 3px;
            }
        }

        &::before {
            top: 18px;
            left: 10px;
            width: 12px;
            height: 13px;
            background-size: 12px auto;
        }
    }
}

// Css fix on IE 11
@media all and (-ms-high-contrast:none) {
    .qaQuest {
        padding-top: 12px;
    }
}
