// -----------------------------------------------------------------------------
// Breadcrumbs style
// -----------------------------------------------------------------------------
.breadcrumbWrap {
    list-style: none;
    background-color: $color-blue01;
    padding: 7px 75px 8px 45px;

    .breadcrumb {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    li {
        font-size: 1.2rem;
        line-height: 1.67;

        &+li:before {
            padding: 0 5px;
            color: $color-black;
            content: "＞";
        }

        a {
            color: $color-blue02;
            text-decoration: none;

            &:hover {
                color: #01447e;
                text-decoration: underline;
            }
        }
    }

    @include media-down(md) {
        background-color: $color-white;
        padding: 20px 25px
    }
}
