// -----------------------------------------------------------------------------
// List style
// -----------------------------------------------------------------------------
.choseList {}

.choseItem {
    display: flex;
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    .image {
        width: 200px;
        height: 150px;
        margin-right: 30px;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        // style for IE11
        &.isIE {
            background-size: cover;
            background-position: 50% 50%;

            img {
                display: none;
            }
        }
    }

    .choseBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 230px);
        padding-top: 10px;

        .textList {
            li {
                position: relative;
                padding-left: 18px;
                font-weight: bold;
                font-size: 1.7rem;
                line-height: 1.6;

                &::after {
                    position: absolute;
                    border-radius: 50%;
                    left: 7px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    background-color: $text-color;
                    content: '';
                }

                @include media-down(1500) {
                    font-size: 1.3vw;
                }

                @include media-down(lg) {
                    font-size: 1.7rem;
                }

                @include media-down(sp) {
                    font-size: 1.5rem;
                }
            }
        }

        .departBtn {
            margin-top: 5px;
        }
    }

    @include media-down(sp) {
        flex-direction: column;

        .image {
            margin: 0 auto 15px auto;
            width: 164px;
            height: 123px;
        }

        .choseBox {
            width: 100%;
            padding-top: 0;

            .textList {
                margin-bottom: 10px;
            }
        }
    }
}

.linkList {
    li {
        margin-bottom: 11px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media-down(md) {

        li {
            margin-bottom: 6px;
        }
    }
}
