// -----------------------------------------------------------------------------
// Table style
// -----------------------------------------------------------------------------
.tableBlock01 {
    @include media-down(1500) {
        overflow: auto;
        margin-right: -40px;
        .tableBox {
            padding-right: 40px;
            width: min-content;
        }
    }
    @include media-down(md) {
        overflow: inherit;
        margin-right: 0;
        .tableBox {
            padding-right: 0;
            width: 100%;
        }
    }
    @include media-down(sp) {
        overflow: auto;
        margin-right: -20px;
        .tableBox {
            padding-right: 20px;
            width: min-content;
        }
    }
}
.table01 {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th,td {
        border: 1px solid $color-border;
        font-size: 1.4rem;
        line-height: 1.6;
        padding: 10px 10px;
        min-width: 115px;
    }

    thead {
        th {
            background-color: $bg-color03;
            text-align: center;
            font-weight: bold;
            &.col1 {
                width: 22%;
            }
            &.col2 {
                width: 48%;
            }
            &.col3 {
                width: 13%;
            }
            &.col4 {
                width: 17%;
            }
        }
    }
    th {
        font-weight: bold;
    }
    @include media-down(1500) {
        min-width: 820px;
    }
    @include media-down(md) {
        min-width: auto;
    }
    @include media-down(sp) {
        min-width: 820px;
    }
}
