// -----------------------------------------------------------------------------
// Headline style
// -----------------------------------------------------------------------------
.headline1 {
    margin-bottom: 40px;
    padding: 36px 20px;
    background-color: $color-primary;
    font-size: 3.4rem;
    font-weight: bold;
    line-height: 1.6;
    text-align: center;
    color: $color-white;
    @include media-down(sp) {
        margin-bottom: 20px;
        font-size: 2.3rem;
        padding: 12px 25px 12px 25px;
        text-align: left;
    }
}

.headline2 {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: $bg-color05;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: $color-white;
    @include media-down(sp) {
        font-size: 1.7rem;
        padding: 11px 20px;
        text-align: left;
        line-height: 1.3;
    }
}

.headline3 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 15px;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
    &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: $bg-color06;
        content: '';
    }
    &::after {
        position: absolute;
        z-index: 2;
        max-width: 160px;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: $color-green01;
        content: '';
    }
    @include media-down(sp) {
        margin-bottom: 20px;
        padding-bottom: 10px;
        font-size: 2rem;
        line-height: 1.3;
    }
}

// Css fix on IE 11
@media all and (-ms-high-contrast:none) {
    .headline2 {
        padding: 12px 10px 6px 10px;
    }
}

