// -----------------------------------------------------------------------------
// Header style
// -----------------------------------------------------------------------------

@include media-up(md) {
    .fixedHeader {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 99;

        .nav {
            display: flex;

            li {
                width: calc(100% / 7);
                background-color: $color-black02;
                border-top: 1px solid $color-black03;
                border-bottom: 1px solid $color-black03;
                border-left: 1px solid $color-black03;
                transition: all .3s;

                &:last-child {
                    border-right: 1px solid $color-black03;
                }

                &:hover,
                &.active {
                    background-color: $color-green01;
                    border-color: $color-green01;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-white;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.61;
                padding: 17px 15px;
                height: 100%;

                &:hover {
                    text-decoration: none;
                }
            }

            .img {
                display: block;
                line-height: 0;
                margin-right: 12px;

                svg {
                    max-height: 24px;
                }

                .iconNav {
                    &01 {
                        width: 27px;
                    }

                    &02 {
                        width: 18px;
                    }

                    &03 {
                        width: 22px;
                    }

                    &04 {
                        width: 16px;
                    }

                    &05 {
                        width: 22px;
                    }

                    &06 {
                        width: 22px;
                    }

                    &07 {
                        width: 18px;
                    }
                }
            }
        }
    }

    .rHeader {
        background-color: $color-white;
        display: flex;
        position: fixed;
        width: 50%;
        top: 0;
        z-index: 99;

        .nav {
            display: flex;
            flex-wrap: wrap;
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 1.67;
            padding: 6px 0px 0px;
            border-bottom: 1px solid $color-gray01;
            width: calc(100% - 230px);

            li {

                &:last-child {
                    a {
                        border-right: none;
                    }
                }
            }

            a {
                display: block;
                text-align: center;
                padding: 4px 11px 3px;
                border-right: 1px solid $text-color;
                margin-bottom: 5px;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .iconNav {
            width: 230px;
            display: flex;
            align-self: flex-start;
            align-items: baseline;
            position: relative;

            a {
                padding: 5px;
                width: 40px;
                height: 40px;
                min-height: 40px;
                text-align: center;
                background-color: $bg-color01;

                &:not(:last-child) {
                    border-right: 1px solid $color-border03;
                }

                .icon {
                    fill: currentColor;
                }

                &.linkDoc {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-color: $bg-color02;
                    width: 70px;
                    min-width: 70px;
                    height: 53px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    line-height: 1.66;

                    .icon-doc {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.headerFixBlock {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 346px;
    box-shadow: 0px 3px 6px 0px rgba($color-black, $alpha: 0.16);
    z-index: 99;

    .menuBox {
        display: flex;
    }

    .logoBox {
        padding: 20px 13px 0;
        text-align: center;
        background-color: $color-white;

        span {
            display: block;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: 1.3;
            color: $color-primary;
        }

        .logo {
            display: inline-block;
            line-height: 0;
        }
    }
}

.btnMenu {
    display: inline-block;
    width: 111px;
    min-width: 111px;
    min-height: 111px;
    padding: 10px;
    background-color: $color-primary;
    cursor: pointer;
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    color: $color-white;
    text-align: center;


    &:after {
        position: absolute;
        content: 'メニュー';
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0px 10px;
    }

    span {
        display: inline-block;
        position: relative;
        width: 45px;
        background-color: $color-white;
        height: 4px;
        border-radius: 1px;
        margin-top: 20px;
        transition: all .3s;

        &:after,
        &:before {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 4px;
            background-color: $color-white;
            content: "";
            transition: all .3s;
        }

        &:before {
            top: 15px;
        }
    }

    &.active {
        &:after {
            content: '閉じる';
        }

        span {
            background-color: transparent;

            &:after,
            &:before {
                top: 15px;
                transform: translateX(-50%) rotate(45deg);
            }

            &:before {
                transform: translateX(-50%) rotate(-45deg);
            }
        }
    }
}

@include media-down(lg) {
    .fixedHeader {
        .nav {
            a {
                padding: 16px 10px;
                font-size: 1.4rem;
            }
        }
    }

    .rHeader {
        .nav {
            width: calc(100% - 150px);
            font-size: 1.1rem;
        }

        .iconNav {
            width: 150px;
            flex-wrap: wrap;
            padding-right: 70px;

            a {
                width: 50%;
                border-bottom: 1px solid $color-border03;

                &.linkDoc {
                    height: calc(100% + 8px);
                }
            }
        }

    }
}

@include media-down(md) {

    .fixedHeader,
    .rHeader {
        display: none;
    }

    .headerFixBlock {
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 0;
        left: 0;
        max-width: 100%;

        .logoBox {
            padding: 10px 10px 0;
            text-align: left;
            width: calc(100% - 68px);

            span {
                display: none
            }

            .logo {
                img {
                    max-width: 176px;
                }
            }
        }
    }

    .btnMenu {
        order: 1;
        width: 68px;
        min-width: 68px;
        min-height: 68px;
        height: 68px;
        font-size: 1.1rem;

        &:after {
            bottom: 8px;
        }

        span {
            width: 28px;
            height: 2px;
            margin-top: 10px;

            &:after,
            &:before {
                top: 20px;
                height: 2px;
            }

            &:before {
                top: 10px;
            }
        }

        &.active {
            span {

                &:after,
                &:before {
                    top: 10px;
                }
            }
        }

    }
}
